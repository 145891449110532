import Fetch from '@/utils/fetch.js'


// 获取分类
const seats = (data, fn, errorFn, dataLevel) => {
    new Fetch('seats', 'get', data, fn, errorFn, dataLevel)
}
const seatSelects = (fn, errorFn, dataLevel) => {
    new Fetch('seat_selects', 'get', [], fn, errorFn, dataLevel)
}
export {
    seats,
    seatSelects
}