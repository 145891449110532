<template>
	<div class="ielts-page">
		<div class="ielts-tab-box">
			<div class="ielts-tab">
				<div class="box"></div>
				<div
					class="ielts-tab-item"
					v-for="item in ieltsTab"
					:key="item.url"
				>
					<router-link :to="item.url">
						<div class="" :class="`icon-papa-${item.icon}`">
							{{ item.name }}
						</div>
					</router-link>
				</div>
			</div>
		</div>
		<div class="exam-position">
			<div class="exam-position-top">
				<div class="logo">
					<img class="logoPic" src="./img/logo.png" alt="" />
				</div>
				<div class="line"></div>
				<div class="label">雅思考试官方(BC)合作伙伴</div>
				<div class="code">机构代码：TS000184</div>
			</div>
			<div class="title">
				<img src="./img/duck.png" />
				<span>雅思国内考场考位一览</span>
			</div>
			<!--筛选-->
			<div class="selectList">
				<select name="" id="exam-type" v-model="examType" style="  ">
					<option
						v-for="(item, index) in typeList"
						:value="item.id"
						:key="index"
						>{{ item.name }}</option
					>
				</select>
				<select name="" id="exam-month" v-model="examMonth">
					<option
						v-for="(item, index) in dateList"
						:value="item.id"
						:key="index"
						>{{ item.name }}</option
					>
				</select>
				<select name="" id="exam-place" v-model="examPlace">
					<option
						v-for="(item, index) in cityList"
						:value="item.id"
						:key="index"
						>{{ item.name }}</option
					>
				</select>
				<div @click="search">搜索</div>
			</div>
			<!--列表-->
			<template v-if="seatListLength > 0">
				<div v-for="(item, index) in seatList" :key="index">
					<div class="exam-table-top">
						<img src="./img/list_icon.png" />
						<span>考试时间：{{ index }} </span>
					</div>
					<div class="exam-table">
						<div class="exam-table-tr first">
							<div class="exam-table-td">城市</div>
							<div class="exam-table-td">考场名称</div>
							<div class="exam-table-td">考试类型</div>
							<div class="exam-table-td">考试方式</div>
							<div class="exam-table-td">
								报名及转考、退考截止日期
							</div>
							<div class="exam-table-td">考位状态</div>
						</div>
						<div
							class="exam-table-tr"
							v-for="val in item"
							:key="val.city"
						>
							<div class="exam-table-td">{{ val.city }}</div>
							<div class="exam-table-td">
								{{ val.center_name }}
							</div>
							<div class="exam-table-td">{{ val.exam_type }}</div>
							<div class="exam-table-td">{{ val.exam_way }}</div>
							<div class="exam-table-td">{{ val.ended_at }}</div>
							<div class="exam-table-td">
								{{ val.seat_status }}
							</div>
						</div>
					</div>
				</div>
			</template>
			<!--缺省 noSeats.png-->
			<div v-if="seatListLength == 0" class="noSeats">
				<img src="./img/noSeats.png" />
				<div>
					找不到你要的内容
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import * as Api from '@/api/examPosition/examPosition';

export default {
	components: {},
	data() {
		return {
			cityList: [],
			seatList: [],
			dateList: [],
			typeList: [],
			examType: '',
			examMonth: '',
			examPlace: '',
			ieltsTab: [
				{
					url: '/ielts/index',
					name: '首页',
				},
				{
					url: '/ielts/community',
					name: '公益课',
				},
				{
					url: '/ielts/activity',
					name: '训练营',
				},
				{
					url: '/ielts/shop',
					name: '课程商城',
				},
				{
					url: '/ielts/spoken',
					name: '口语素材',
				},
				{
					url: '/ielts/material',
					name: '资料中心',
				},
				{
					url: '/ielts/article',
					name: '精品文章',
				},
				// {
				//   url: '/ExamPosition',
				//   name: '雅思资讯'
				// }
			],
			seatListLength: 0,
		};
	},
	mounted() {
		this.getSelect();
		this.$router.push({
			path: '/',
		});
	},
	activated() {},
	computed: {},
	methods: {
		getData(params) {
			Api.seats(params, (res) => {
				if (res.length > 0) {
					this.seatListLength = res.length;
					var list = {};
					res.forEach(function(v) {
						if (list[v.examed_at]) {
							list[v.examed_at].push(v);
						} else {
							list[v.examed_at] = [];
							list[v.examed_at].push(v);
						}
					});
					this.seatList = list;
				} else {
					this.seatListLength = 0;
				}
			});
		},
		getSelect() {
			Api.seatSelects((res) => {
				this.cityList = res.provinces;
				this.dateList = res.dates;
				this.typeList = res.type;
				this.examMonth = res.dates[0].id;
				this.examType = res.type[0].id;
			});
		},
		search() {
			let params = {
				type: this.examType,
			};
			if (this.examMonth) {
				params.date = this.examMonth;
			}
			if (this.examPlace) {
				params.province_id = this.examPlace;
			}
			this.getData(params);
		},
	},
	watch: {},
};
</script>

<style lang="less" scoped>
.exam-position {
	width: 1170px;
	margin: 35px auto 0px;

	& > .exam-position-top {
		text-align: left;
		font-family: PingFang SC;

		& > div {
			display: inline-block;
			vertical-align: top;
		}

		& > .label {
			color: #333333;
			font-size: 20px;
			font-weight: 600;
			border-left: 1px solid #e6e6e6;
			padding-left: 18px;
			margin-right: 12px;
		}

		& > .code {
			color: #555555;
			font-size: 14px;
			line-height: 28px;
		}
	}

	& > .selectList {
		margin-top: 24px;
		display: flex;

		& > select,
		& > input {
			flex: 1;
			margin-right: 14px;
			border: 1px solid #e6e6e6;
			border-radius: 4px;
			height: 46px;
			color: #999999;
			padding-left: 19px;
		}

		& > div {
			flex-grow: 0.27;
			line-height: 46px;
			font-size: 14px;
			background: #29d087;
			color: #fff;
			border-radius: 4px;
			cursor: pointer;
		}
	}

	& > .noSeats {
		color: #999999;
		margin-top: 80px;
		font-size: 16px;
	}

	& > div {
		& > .exam-table-top {
			margin-top: 16px;
			background: #29d087;
			color: #fff;
			text-align: left;
			padding-left: 30px;
			border-radius: 4px 4px 0 0;

			& > img {
				margin-right: 8px;
				position: relative;
				top: 3px;
			}

			& > span {
				height: 50px;
				line-height: 50px;
			}
		}

		& > .exam-table {
			display: table;
			width: 1170px;
			display: table;
			width: 1168px;
			border-left: 1px solid #e6e6e6;
			border-right: 1px solid #e6e6e6;

			& > .exam-table-tr {
				display: table-row;
				height: 80px;
				line-height: 80px;
				font-size: 14px;
				color: #333333;

				& > .exam-table-td {
					display: table-cell;
					border-bottom: 1px solid #e6e6e6;
				}
			}

			& > .exam-table-tr.first {
				height: 60px;
				line-height: 60px;
				font-size: 16px;
				font-weight: 600;
			}
		}
	}

	& > .title {
		margin-top: 20px;

		& > img {
			position: relative;
			top: 8px;
			margin-right: 12px;
		}

		& > span {
			color: #333333;
			font-size: 36px;
			font-weight: 600;
		}
	}
}
</style>
